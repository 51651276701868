import React from "react"
import { Link } from "gatsby"
import slugify from "../utils/slugify"
import {
	Drawer,
	DrawerContent,
	DrawerCloseButton,
	DrawerTrigger,
} from "./primitives/drawer"
import { ScrollableLink } from "./dynamic-anchors"
import Accordion, {
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	AccordionIcon,
} from "../components/primitives/accordion"
import TrapofitLogo from "./trapofit-logo"
import LogoClaim from "../images/Logo_mit_Claim.svg"
import Hamburger from "../images/hamburger.svg"
import PurpleButton from "./purple-button"
import AnchorLink from "./anchor-link"
import { Box } from "./primitives/base"

interface MobileNavOpenProps {
	mobileNavContent?: {
		accordionLink?: {
			title?: string
			content?: string[]
		}
		normalLink?: {
			title?: string
		}[]
	}[]
	phoneNumber?: string
	LogoType?: string
	googleLink?: string
	facebookLink?: string
}

const MobileNavOpen = ({
	mobileNavContent,
	phoneNumber,
	LogoType,
	googleLink,
	facebookLink,
}: MobileNavOpenProps) => {
	return (
		<Box
			as={Drawer}
			xl={{
				display: "none",
			}}
			lg={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div
				css={{
					width: "100%",
					display: "flex",
					position: "relative",
					alignItems: "flex-start",
					flexDirection: "column",
				}}
			>
				<div
					css={{
						width: "100%",
						display: "flex",
						alignItems: "flex-start",
						alignItems: "center",
						justifyContent: "flex-start",
					}}
				>
					<div
						css={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							paddingTop: "32px",
							paddingBottom: "32px",
						}}
					>
						{LogoType === "country" ? (
							<Link to="/de/">
								<DrawerCloseButton>
									<Box
										as={TrapofitLogo}
										xl={{
											width: "180px",
											transition: "all 0.3s ease-in-out",
										}}
										lg={{
											width: "120px",
											height: "36px",
											marginRight: "24px",
										}}
									/>
								</DrawerCloseButton>
							</Link>
						) : (
							<Link to="/de/">
								<DrawerCloseButton>
									<Box
										as={LogoClaim}
										xl={{
											width: "180px",
											transition: "all 0.3s ease-in-out",
										}}
										lg={{
											width: "120px",
											height: "36px",
											marginRight: "24px",
										}}
									/>
								</DrawerCloseButton>
							</Link>
						)}
						<DrawerTrigger css={{ paddingBottom: "6px" }}>
							<Hamburger
								css={{
									width: "54px",
									height: "24px",
								}}
							/>
						</DrawerTrigger>
					</div>
				</div>
				<DrawerContent
					placement="left"
					size="full"
					css={{
						display: "flex",
						flexDirection: "column",
						height: "100vh",
					}}
				>
					<div
						css={{
							width: "100%",
							alignItems: "center",
							paddingLeft: "24px",
							paddingRight: "0",
							paddingTop: "32px",
							paddingBottom: "20px",
							justifyContent: "space-between",
							display: "flex",
						}}
					>
						{LogoType === "country" ? (
							<Link to="/de/">
								<DrawerCloseButton className="p">
									<TrapofitLogo
										css={{
											width: "180px",
											height: "36px",
											objectFit: "cover",
											marginRight: "24px",
											width: "112px",
											height: "24px",
										}}
									/>
								</DrawerCloseButton>
							</Link>
						) : (
							<Link to="/de/">
								<DrawerCloseButton className="p">
									<LogoClaim
										css={{
											width: "180px",
											height: "46px",
											objectFit: "cover",
											marginRight: "24px",
											color: "#FFFFFF",
											width: "120px",
										}}
									/>
								</DrawerCloseButton>
							</Link>
						)}
						<DrawerCloseButton
							css={{
								width: "54px",
								height: "24px",
								color: "#fff",
							}}
						/>
					</div>

					<Accordion
						css={{
							width: "100%",
							paddingTop: "24px",
							height: "calc(100% - 116px)",
							overflow: "auto",
						}}
					>
						<AccordionItem>
							<div
								css={{
									width: "100%",
									maxWidth: "auto",
									display: "flex",
									alignItems: "center",
									paddingLeft: "24px",
									paddingBottom: "20px",
									paddingRight: "24px",
									flexDirection: "column",
									backgroundColor: "#222222",
									justifyContent: "center",
								}}
							>
								<div
									css={{
										width: "100%",
										display: "flex",
										alignItems: "flex-start",
										flexDirection: "column",
										width: "100%",
										maxWidth: "auto",
									}}
								>
									{mobileNavContent?.accordionLink && (
										<div css={{ width: "100%" }}>
											<AccordionButton>
												<div
													css={{
														width: "100%",
														display: "flex",
														alignItems: "center",
														justifyContent: "space-between",
														transition: "padding 0.2s ease",
														"button[data-state=closed] &": {
															paddingBottom: "27px",
														},
													}}
												>
													<span
														className="menu-text"
														css={{
															color: "#B7B7B7",
															"button[data-state=open] &": {
																color: "#FFFFFF",
															},
														}}
													>
														{/* <Link to={props.accordionLink}> */}
														{mobileNavContent?.accordionLink?.title}
														{/* </Link> */}
													</span>
													<AccordionIcon
														css={{
															color: "#B7B7B7",
															"button[data-state=open] &": {
																color: "#FFFFFF",
															},
														}}
													/>
												</div>
											</AccordionButton>
											<AccordionPanel
												css={{ width: "100%", paddingBottom: "20px" }}
											>
												<div
													css={{
														width: "100%",
														display: "flex",
														transition: "all 0.3s ease-in-out",
														overflow: "hidden",
														alignItems: "flex-start",
														flexDirection: "column",
													}}
												>
													{mobileNavContent?.accordionLink?.content.map(
														(item, i) => {
															if (
																typeof item.link === "string" &&
																item.link.startsWith("http")
															) {
																return (
																	<a
																		href={item.link}
																		target="_blank"
																		rel="noreferrer"
																		key={item.title}
																		css={{
																			width: "100%",
																			borderWidth:
																				i !==
																				mobileNavContent?.accordionLink?.content
																					.length -
																					1
																					? "0px 0px 1px"
																					: "0px",
																			borderColor: "#535353",
																			// paddingTop: "5px",
																			// paddingBottom: "5px",
																		}}
																	>
																		<DrawerCloseButton
																			key={item}
																			className="p"
																			css={{
																				color: "#B7B7B7",
																				width: "100%",
																				paddingTop: "5px",
																				paddingBottom: "5px",
																				display: "flex",
																				fontSize: "16px",
																				lineHeight: "39px",
																			}}
																		>
																			{item.title}
																		</DrawerCloseButton>
																	</a>
																)
															}
															return (
																<AnchorLink
																	to={item.link}
																	key={item.title}
																	css={{
																		width: "100%",
																		borderWidth:
																			i !==
																			mobileNavContent?.accordionLink?.content
																				.length -
																				1
																				? "0px 0px 1px"
																				: "0px",
																		borderColor: "#535353",
																		// paddingTop: "5px",
																		// paddingBottom: "5px",
																	}}
																>
																	<DrawerCloseButton
																		key={item}
																		className="p"
																		css={{
																			color: "#B7B7B7",
																			width: "100%",
																			paddingTop: "5px",
																			paddingBottom: "5px",
																			display: "flex",
																			fontSize: "16px",
																			lineHeight: "39px",
																		}}
																	>
																		{item.title}
																	</DrawerCloseButton>
																</AnchorLink>
															)
														}
													)}
												</div>
											</AccordionPanel>
										</div>
									)}
									{mobileNavContent?.normalLink.map((item, index) => {
										if (
											typeof item.link === "string" &&
											item.link.startsWith("http")
										) {
											return (
												<a
													target="_blank"
													href={item.link}
													key={item.title}
													css={{
														display: "block",
														width: "100%",
														paddingBottom: "6px",
													}}
													rel="noreferrer"
												>
													<DrawerCloseButton
														className="menu-text"
														css={{
															width: "100%",
															textAlign: "left",
															width: "100%",
															paddingBottom: "20px",
															color: "#B7B7B7",
														}}
													>
														{item.title}
													</DrawerCloseButton>
												</a>
											)
										}
										return (
											<AnchorLink
												to={item.link}
												key={item.title}
												css={{
													display: "block",
													width: "100%",
													paddingBottom: "6px",
												}}
											>
												<DrawerCloseButton
													className="menu-text"
													css={{
														width: "100%",
														textAlign: "left",
														width: "100%",
														paddingBottom: "20px",
														color: "#B7B7B7",
													}}
												>
													{item.title}
												</DrawerCloseButton>
											</AnchorLink>
										)
									})}
									{phoneNumber && (
										<div className="button-text" css={{ width: "100%" }}>
											<PurpleButton
												isGradient={false}
												size="xl"
												onClick={() => {
													window.dataLayer.push({ event: `phone_click_text` })
													window.location.href = `tel: ${phoneNumber}`
												}}
												css={{
													width: "100%!important",
													display: "flex",
													marginTop: "18px",
													alignItems: "center",
													justifyContent: "center",
													borderRadius: "8px",
													color: "white",
													textAlign: "center",
												}}
											>
												Anrufen {phoneNumber}
											</PurpleButton>
										</div>
									)}
								</div>
							</div>
						</AccordionItem>
					</Accordion>
					<div
						css={{
							width: "100%",
							display: "flex",
							position: "absolute",
							bottom: "0",
							alignItems: "flex-start",
							paddingLeft: "24px",
							paddingRight: "24px",
							alignItems: "center",
							paddingLeft: "24px",
							paddingRight: "24px",
							justifyContent: "center",
						}}
					>
						<div
							css={{
								width: "100%",
								display: "flex",
								paddingBottom: "16px",
								left: "0px",
								right: "0px",
								bottom: "24px",
								maxWidth: "auto",
								alignSelf: "center",
								alignItems: "flex-end",
								justifyContent: "space-between",
							}}
						>
							{facebookLink && (
								<span
									css={{
										color: "#B7B7B7",
										fontSize: "16px",
									}}
								>
									<a
										href={facebookLink}
										css={{
											"&:hover": {
												color: "var(--secundary-color)",
												textDecoration: "none",
											},
										}}
									>
										Facebook
									</a>
								</span>
							)}
							{googleLink && (
								<span
									css={{
										color: "#B7B7B7",
										fontSize: "16px",
									}}
								>
									<a
										href={googleLink}
										css={{
											"&:hover": {
												color: "var(--secundary-color)",
												textDecoration: "none",
											},
										}}
									>
										Google
									</a>
								</span>
							)}
						</div>
					</div>
				</DrawerContent>
			</div>
		</Box>
	)
}

export default MobileNavOpen
